<template>
  <div class="profile-page-container">
    <v-container v-if="profileData?.mainPhotoUrl" class="image-wrapper">
      <img
          :src="apiUrl + profileData?.mainPhotoUrl"
          alt="Profile Image"
          class="profile-image"
      />
    </v-container>
    <div class="small-text">Ku pamięci</div>
    <h2 class="big-text">
      <span>{{ profileData?.firstName }} {{ profileData?.secondName || '' }}</span><br>
      <span>{{ profileData?.lastName }}</span>
    </h2>
    <p class="small-text">
      {{
        profileData?.birthDate &&
        profileData.birthDate.day &&
        profileData.birthDate.month &&
        profileData.birthDate.year
            ? `${profileData.birthDate.day}.${profileData.birthDate.month}.${profileData.birthDate.year}`
            : ''
      }}
    </p>
    <p class="small-text">{{ profileData?.birthPlace }}</p>
    <div class="small-text">✶</div>
    <p class="small-text">
      {{
        profileData?.deathDate &&
        profileData.deathDate.day &&
        profileData.deathDate.month &&
        profileData.deathDate.year
            ? `${profileData.deathDate.day}.${profileData.deathDate.month}.${profileData.deathDate.year}`
            : ''
      }}
    </p>
    <p class="small-text">{{ profileData?.deathPlace }}</p>
    <button class="btn-history" @click="$emit('scroll-to-second-page')">
      <span>Poznaj historię</span>
      <v-icon class="icon">mdi-arrow-down</v-icon>
    </button>
  </div>
</template>

<script>
export default {
  name: 'CoverPage',
  props: {
    profileData: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['scroll-to-second-page'],
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL
    };
  },
}
</script>

<style lang="scss" scoped>
.image-wrapper {
  padding-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

h2 {
  font-size: 32px; /* Adjusted to match the design */
  margin-bottom: 16px; /* Space between name and surname */
  line-height: 48px;
}
</style>
