<template>
  <div class="profile-page-container">
    <div class="header">
      <v-icon class="top-icon">mdi-home-heart</v-icon>
      <div class="title big-text">Rodzina</div>
    </div>
    <div v-for="[relation, members] in categorizedFamilyData" :key="relation" class="family-section">
      <h2 class="relation-title small-text">{{ getRelationTitle(relation) }}</h2>
      <div class="family-members">
        <div v-for="member in members" :key="member.id" class="family-member">
          <div class="member-icon">
            <img alt="Member Icon" src="@/assets/images/family_member_icon.png"/>
          </div>
          <div class="member-name">{{ member.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FamilyPage',
  props: {
    familyData: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    categorizedFamilyData() {
      const categories = {};
      this.familyData.forEach(member => {
        if (!categories[member.relation]) {
          categories[member.relation] = [];
        }
        categories[member.relation].push(member);
      });
      return Object.entries(categories).filter(([_, members]) => members.length > 0);
    }
  },
  methods: {
    getRelationTitle(relation) {
      const titles = {
        marriage: 'Małżeństwo',
        child: 'Dzieci',
        parent: 'Rodzice',
        sibling: 'Rodzeństwo'
      };
      return titles[relation] || '';
    }
  }
};
</script>

<style lang="scss" scoped>
.header .home-icon {
  font-size: 24px;
  display: inline-block;
}

.family-section {
  margin-top: 16px;
}

.family-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.family-member {
  margin: 0.3em;
  text-align: center;
}

.member-icon {
  margin-bottom: 4px;

  img {
    width: 30px;
    height: 30px;
  }
}
</style>
