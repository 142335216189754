<template>
  <div class="profile-page-container">
    <v-icon class="top-icon">mdi-candle</v-icon>
    <div class="small-text">Pozostanie w pamięci</div>
    <v-container v-if="profileData?.mainPhotoUrl" class="image-wrapper">
      <img
          :src="apiUrl + profileData.mainPhotoUrl"
          alt="Profile Image"
          class="final-page-profile-image"
      />
    </v-container>
    <h2 class="big-text">
      <span>{{ profileData?.firstName }} {{ profileData?.secondName || '' }}</span><br>
      <span>{{ profileData?.lastName }}</span>
    </h2>
    <p class="small-text">{{ formatBirthDate(profileData.birthDate) }}</p>
    <p class="small-text">{{ profileData.birthPlace }}</p>
    <div class="small-text">✶</div>
    <p class="small-text">{{ formatDeathDate(profileData.deathDate) }}</p>
    <p class="small-text">{{ profileData.deathPlace }}</p>
    <button class="btn-return" @click="$emit('scroll-to-top')">
      <span>Przejdź do początku</span>
      <v-icon class="icon">mdi-refresh</v-icon>
    </button>
  </div>
</template>

<script>
export default {
  name: 'FinalPage',
  props: {
    profileData: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['scroll-to-top'],
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL
    };
  },
  methods: {
    formatBirthDate(birthDate) {
      return birthDate ? `${birthDate.day}.${birthDate.month}.${birthDate.year}` : '';
    },
    formatDeathDate(deathDate) {
      return deathDate ? `${deathDate.day}.${deathDate.month}.${deathDate.year}` : '';
    }
  },
}
</script>

<style lang="scss" scoped>
.image-wrapper {
  padding-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

h2 {
  font-size: 32px; /* Adjusted to match the design */
  margin-bottom: 16px; /* Space between name and surname */
  line-height: 48px;
}

.final-page-profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 16px;
}
</style>
